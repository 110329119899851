<template>
  <div class="ordinary">
    <div class="ordinary-top">
      <div class="top-content">
        <div class="img">
          <v-img  :attachment-id="solutionInfo.h5Banner" :default-img="require('@/assets/img/kctp.png')"/>
        </div>
        <div class="text">

          <p class="text-title">{{solutionInfo.name}}</p>
          <div class="tag">
            <div v-for="(item,index) in taglist" :key="index">{{item}}</div>
          </div>
          <div class="text-bottom" @click="show=true">
            <p>{{solutionInfo.solutionDiscription}}</p>
            <div class="btn">
              <img :src="other_img.img1" alt="图片">
            </div>
          </div>


        </div>
      </div>
      <div class="top-info"   v-if="solutionInfo.hotType==5">
        <div>
          <div class="name">
            <p>
              <van-icon name="contact" color="#666666" />
              发布者：</p>
            <p class="name-con">{{solutionInfo.publishedBy}}</p>
          </div>
          <div class="time">
            <p>
              <van-icon name="clock-o" color="#666666" />
              发布时间：</p>
            <p>{{time}}</p>
          </div>
        </div>
       <p>温馨提示：如有涉及侵权，请联系平台下架</p>
    </div>
    </div>


    <van-overlay class="over-box" :show="show" @click="show = false" >
      <div class="box">
        <p>概述</p>
        <p>{{solutionInfo.solutionDiscription}}</p>
      </div>
    </van-overlay>


    <div class="imglist-box">
      <v-img  class="img" v-for="(item,index) of imgList" :attachment-id="item.url" :key="item.name" @click="preview_img(index)" :default-img="require('@/assets/img/kctp.png')"/>
    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import VImg from '@/components/VImg'
import { getSolutionInfo } from '@/api/solution'
import { ImagePreview } from 'vant';
import share from '../components/share.vue'
export default {
  name: 'ordinary_program',
  components: {
    VImg,
    share
  },

  computed:{
    id: function () {
      return this.$route.query.id
    },
  },

  data () {
    return {
      solutionInfo:'',
      other_img:{
        img1:require("@/assets/img/paogram1.png")
      },
      show:false,
      taglist:[],
      time:'',
      imgList:[],
      imgurlList:[],
      shareInfo: {
          title: '',
          desc: '',
          img: ''
      }
    }
  },

  mounted(){
    document.title = '寻｜解决方案'
    this.loadGetSolutionInfo()

  },

  methods:{
    preview_img(index){
      ImagePreview({
        images:this.imgurlList,
        startPosition:index
      })

    },


    loadGetSolutionInfo(){
      getSolutionInfo(this.id).then((res)=>{
        this.solutionInfo = res
        this.shareInfo.title = res.name
        this.shareInfo.desc = res.solutionDiscription
        this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.h5Banner}`
        this.taglist = res.tag.split(',')
         if(res.hotType==5){
           this.time=(res.publishedTime).substring(0,10)
         }


        this.imgList=new Set( res.imgList)

        for(let item of this.imgList){
          let url=`${process.env.VUE_APP_OBS_PREFIX}/${item.url}`
          this.imgurlList.push(url)
        }

      })
    },
  },
}
</script>

<style scoped lang="scss">
.ordinary{
  background: #EEEEEE;
  .over-box{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 80px;
    .box{
      width: 327px;
      padding: 24px;
      border-radius: 16px;
      background: #FFFFFF;
      :nth-child(1){
        font-size: 20px;
        font-weight: bold;
        color: #262626;
      }
      :nth-child(2){
        font-size: 16px;
        font-weight: 500;
        color: #868B95;
        margin-top: 16px;
        line-height: 28px;
      }
    }

  }
  .ordinary-top{
    background: #FFFFFF;
    margin-bottom: 6px;
      .top-content{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        .img{
          margin-right: 20px;
          >img{
            width: 108px;
            height: 153px;
            border: 1px solid #EEEEEE;
          }
        }
        >.text{
          min-height: 153px;
          padding: 4px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .text-title{
            font-size: 22px;
            color: #262626;
            font-weight: Bold;
            margin-bottom: 17px;
          }
           .tag{
             margin-bottom: 17px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            >div{
              background: #F6F6F6;
              border-radius: 3px;
              padding: 2.6px 6px 1px;
              margin-right: 8px;
              margin-bottom: 5px;
              font-size: 12px;
              line-height: 17px;
              //font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
            }

          }

          > .text-bottom{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            >p{
              width: 193px;
              font-size: 12px;
              color: #666666;
              font-weight: 500;

              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            >.btn{
              height: 100%;
              width: 22px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              >img{
                display: block;
                width: 8px;
                height: 8px;
              }
            }
          }



        }
      }

      .top-info{
          padding:16px;
          border-top: 1px solid #EEEEEE;
          >:first-child{
            display: flex;
            justify-content: space-between;


            >.name{
              display: flex;
              justify-content: flex-start;



              font-size: 12px;
              font-weight: 500;
              color: #999999;
              :first-child{
                color: #666666;
              }
              .name-con{
                max-width: 120px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
            }
            >.time{
              display: flex;
              justify-content: flex-start;

              font-size: 12px;
              font-weight: 500;
              color: #999999;
              :first-child{
                color: #666666;
              }
            }
          }
          >:last-child{
            margin-top: 16px;
            font-size: 12px;
            font-weight: 500;
            color: #999999;
          }
      }
  }
  .imglist-box{
    padding: 16px 16px 32px 16px;
    background: #FFFFFF;
    >.img{
      display: block;
      max-width: 100%;
      max-height: 532px;
      margin: 0 auto;
    }
  }

}


</style>
